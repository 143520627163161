<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	export let paddingClass = 'p-4 sm:p-6 lg:p-8';
	export let baseClass = `${paddingClass} w-full bg-white rounded-lg border border-gray-200 shadow-sm `;
	export let extraClass = '';
	export let height = undefined;
	export let maxWidth = '600px';
	export let fullWidth = false;

	const maxWidthStyle = !fullWidth ? `max-width: ${maxWidth};` : '';

	const handleClick = () => {
		dispatch('click');
	};
</script>

<div
	class="{baseClass} {extraClass} {$$props.class}"
	style="{maxWidthStyle} {$$props.style}"
	bind:clientHeight={height}
	on:click={handleClick}
>
	<slot />
</div>
